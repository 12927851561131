import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { VideoGuide } from '../../models/video-guide.model';
import { VideoPlayback } from '../../models/video-playback.model';
import * as persistActions from '../actions/persist.actions';
import * as videoGuidesActions from '../actions/video-guides.actions';

const selectId = (entity: any) => entity.id;

export const recentVideoGuidesAdapter: EntityAdapter<VideoGuide> = createEntityAdapter<VideoGuide>({
    selectId
});
export const videoGuidesAdapter: EntityAdapter<VideoGuide> = createEntityAdapter<VideoGuide>({
    selectId
});
export const playbacksAdapter: EntityAdapter<VideoPlayback> = createEntityAdapter<VideoPlayback>({
    selectId: entity => entity._id
});
export const featuredVideoGuidesAdapter: EntityAdapter<VideoGuide> = createEntityAdapter<VideoGuide>({
    selectId
});
export interface VideoGuidesState {
    recentVideoGuides: EntityState<VideoGuide>;
    videoGuides: EntityState<VideoGuide>;
    videoGuidesBottomReached: boolean;
    playbacks: EntityState<VideoPlayback>;
    featuredVideoGuides: EntityState<VideoGuide>;
}

export const videoGuidesInitialState: VideoGuidesState = {
    recentVideoGuides: recentVideoGuidesAdapter.getInitialState(),
    videoGuides: videoGuidesAdapter.getInitialState(),
    videoGuidesBottomReached: false,
    playbacks: playbacksAdapter.getInitialState(),
    featuredVideoGuides: featuredVideoGuidesAdapter.getInitialState()
};

export const videoGuidesReducer = createReducer(
    videoGuidesInitialState,
     on(persistActions.setCurrentGame, state => ({
        ...state,
        videoGuides: videoGuidesInitialState.videoGuides,
        videoGuidesBottomReached: false
    })),
    on(videoGuidesActions.loadRecentVideoGuidesSuccess, (state, { payload }) => ({
        ...state,
        recentVideoGuides: recentVideoGuidesAdapter.setAll(payload, state.recentVideoGuides)
    })),
    on(videoGuidesActions.searchVideoGuidesSuccess, (state, { payload }) => ({
        ...state,
        videoGuides: videoGuidesAdapter.addMany(payload.videoGuides, state.videoGuides),
        videoGuidesBottomReached: payload.hasReachedBottom
    })),
    on(videoGuidesActions.newPlaybackSuccess, (state, { payload }) => ({
        ...state,
        playbacks: playbacksAdapter.setOne(payload, state.playbacks)
    })),
    on(videoGuidesActions.updatePlaybackSuccess, (state, { payload }) => ({
        ...state,
        playbacks: playbacksAdapter.updateOne(
            { id: payload._id, changes: payload },
            state.playbacks
        )
    })),
    on(videoGuidesActions.loadFeaturedVideoGuidesSuccess, (state, { payload }) => ({
        ...state,
        featuredVideoGuides: featuredVideoGuidesAdapter.setAll(payload, state.featuredVideoGuides)
    })),
);
