import { createAction, props } from '@ngrx/store';
import { APIError } from '../../models/error.model';
import { VideoGuide } from '../../models/video-guide.model';
import { VideoPlayback } from '../../models/video-playback.model';

export enum VideoGuidesActionsTypes {
    LoadRecentVideoGuides = '[Dashboard Home] Load Recent Video Guides',
    LoadRecentVideoGuidesSuccess = '[Content API] Load Recent Video Guides Success',
    LoadRecentVideoGuidesFailure = '[Content API] Load Recent Video Guides Failure',

    LoadFeaturedVideoGuides = '[Base Home] Load Featured Video Guides',
    LoadFeaturedVideoGuidesSuccess = '[Content API] Load Featured Video Guides Success',
    LoadFeaturedVideoGuidesFailure = '[Content API] Load Featured Video Guides Failure',

    SearchVideoGuides = '[Dashboard Guides] Search Video Guides',
    SearchVideoGuidesSuccess = '[Content API] Search Video Guides Success',
    SearchVideoGuidesFailure = '[Content API] Search Video Guides Failure',

    NewPlayback = '[Video Player Service] New Playback',
    NewPlaybackSuccess = '[Content API] New Playback Success',
    NewPlaybackFailure = '[Content API] New Playback Failure',

    UpdatePlayback = '[Video Player Service] Update Playback',
    UpdatePlaybackSuccess = '[Content API] Update Playback Success',
    UpdatePlaybackFailure = '[Content API] Update Playback Failure'
}

export const loadRecentVideoGuides = createAction(VideoGuidesActionsTypes.LoadRecentVideoGuides);
export const loadRecentVideoGuidesSuccess = createAction(
    VideoGuidesActionsTypes.LoadRecentVideoGuidesSuccess,
    props<{ payload: VideoGuide[] }>()
);
export const loadRecentVideoGuidesFailure = createAction(
    VideoGuidesActionsTypes.LoadRecentVideoGuidesFailure,
    props<{ payload: APIError }>()
);

export const loadFeaturedVideoGuides = createAction(VideoGuidesActionsTypes.LoadFeaturedVideoGuides);
export const loadFeaturedVideoGuidesSuccess = createAction(
    VideoGuidesActionsTypes.LoadFeaturedVideoGuidesSuccess,
    props<{ payload: VideoGuide[] }>()
);
export const loadFeaturedVideoGuidesFailure = createAction(
    VideoGuidesActionsTypes.LoadFeaturedVideoGuidesFailure,
    props<{ payload: APIError }>()
);

export const searchVideoGuides = createAction(
    VideoGuidesActionsTypes.SearchVideoGuides,
    props<{
        payload: {
            term: string;
            searchType: string;
            sortingType: string;
            pageNumber: number;
            skipFirstPage: boolean;
            size?: number;
            playerId?: string;
            role?: string;
            courseId?: string;
        };
    }>()
);
export const searchVideoGuidesSuccess = createAction(
    VideoGuidesActionsTypes.SearchVideoGuidesSuccess,
    props<{ payload: { videoGuides: VideoGuide[]; hasReachedBottom: boolean } }>()
);
export const searchVideoGuidesFailure = createAction(
    VideoGuidesActionsTypes.SearchVideoGuidesFailure,
    props<{ payload: APIError }>()
);

export const newPlayback = createAction(
    VideoGuidesActionsTypes.NewPlayback,
    props<{
        payload: {
            videoId: string;
            videoGuideId: string;
            videoVersion: 'full' | 'free';
            videoIsRedeemed: boolean;
        };
    }>()
);
export const newPlaybackSuccess = createAction(
    VideoGuidesActionsTypes.NewPlaybackSuccess,
    props<{ payload: VideoPlayback }>()
);
export const newPlaybackFailure = createAction(
    VideoGuidesActionsTypes.NewPlaybackFailure,
    props<{ payload: APIError }>()
);

export const updatePlayback = createAction(
    VideoGuidesActionsTypes.UpdatePlayback,
    props<{
        payload: {
            playbackId: string;
            data: Partial<VideoPlayback>;
        };
    }>()
);
export const updatePlaybackSuccess = createAction(
    VideoGuidesActionsTypes.UpdatePlaybackSuccess,
    props<{ payload: VideoPlayback }>()
);
export const updatePlaybackFailure = createAction(
    VideoGuidesActionsTypes.UpdatePlaybackFailure,
    props<{ payload: APIError }>()
);
