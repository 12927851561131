import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VideoGuideViewModel } from '../../models/video-guide.model';
import { VideoPlayback } from '../../models/video-playback.model';
import {
    playbacksAdapter,
    recentVideoGuidesAdapter,
    videoGuidesAdapter,
    VideoGuidesState
} from '../reducers/video-guides.reducer';
import { selectProgressVideosMap, selectSeenVideosMap } from './meta.selectors';
import { selectRedeemedItemIds } from './referral.selectors';
import { selectCustomRouteParamFragment } from './route.selectors';
import { mapVideoGuidesMetadata, selectCurrentCourseVideoGuides } from './shared-content.selectors';
import { selectIsPremium } from './user.selectors';

const selectVideoGuidesState = createFeatureSelector<VideoGuidesState>('videoGuides');
const recentVideoGuidesSubstateState = createSelector(
    selectVideoGuidesState,
    (state: VideoGuidesState) => state.recentVideoGuides
);
const videoGuidesSubstateState = createSelector(
    selectVideoGuidesState,
    (state: VideoGuidesState) => state.videoGuides
);
const playbacksSubstateState = createSelector(
    selectVideoGuidesState,
    (state: VideoGuidesState) => state.playbacks
);

const recentVideoGuideSelectors = recentVideoGuidesAdapter.getSelectors();
const videoGuideSelectors = videoGuidesAdapter.getSelectors();
const playbacksSelectors = playbacksAdapter.getSelectors();

export const selectAllRecentVideoGuides = createSelector(
    recentVideoGuidesSubstateState,
    recentVideoGuideSelectors.selectAll
);
export const selectAllVideoGuides = createSelector(
    videoGuidesSubstateState,
    videoGuideSelectors.selectAll
);
export const selectAllPlaybacks = createSelector(
    playbacksSubstateState,
    playbacksSelectors.selectAll
);
export const selectRecentVideoGuides = createSelector(
    selectAllRecentVideoGuides,
    selectSeenVideosMap,
    selectProgressVideosMap,
    selectIsPremium,
    selectRedeemedItemIds,
    mapVideoGuidesMetadata
);
export const selectVideoGuides = createSelector(
    selectAllVideoGuides,
    selectSeenVideosMap,
    selectProgressVideosMap,
    selectIsPremium,
    selectRedeemedItemIds,
    mapVideoGuidesMetadata
);
export const selectVideoGuidesBottomReached = createSelector(
   selectVideoGuidesState,
   (state: VideoGuidesState) => state.videoGuidesBottomReached
);
export const selectCurrentPlayback = createSelector(
    selectAllPlaybacks,
    selectCustomRouteParamFragment,
    (playbacks: VideoPlayback[], videoGuideId: string): VideoPlayback | null =>
        (playbacks && playbacks.length && playbacks.find(p => p.videoGuideId === videoGuideId)) ||
        null
);
export const selectVideoGuideDuration = createSelector(
    selectCurrentCourseVideoGuides,
    (videoGuides: VideoGuideViewModel[], [courseId, videoId]): number => {
        return videoGuides.find(vg => vg.videoId === videoId)?.duration || 0;
    }
);
